import type { ComponentProps, FC } from "react";
import { Flex } from "antd";
import { styled } from "@linaria/react";

import { SCREEN_SM_MAX } from "theme/antd";

import Icon from "./Icon";

type Props = {
  iconProps: ComponentProps<typeof Icon>;
  label: string;
};

const Container = styled(Flex)`
  align-items: center;

  @media screen and (max-width: ${SCREEN_SM_MAX}px) {
    background-color: white;
    border: 1px solid var(--light-grey-color);
    border-radius: 16px;
    padding: 3px;
  }
`;

const Span = styled.span`
  @media screen and (max-width: ${SCREEN_SM_MAX}px) {
    display: none;
  }
`;

const ResponsiveIconLabel: FC<Props> = ({ iconProps, label }) => (
  <Container>
    <Icon {...iconProps} />
    <Span>{label}</Span>
  </Container>
);

export default ResponsiveIconLabel;
