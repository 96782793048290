import { styled } from "@linaria/react";
import type { BadgeProps } from "antd";
import { Badge as AntdBadge } from "antd";
import type { FC } from "react";

import CheckmarkIcon from "components/icons/CheckmarkIcon";
import colors from "theme/colors";

import SvgIcon from "../Icon/SvgIcon";

const BadgeContainer = styled.div`
  background-color: var(--accent-color);
  border-radius: 50%;
  padding: 3px;
`;

type Props = Omit<BadgeProps, "count"> &
  (
    | { count?: React.ReactNode; showCheckmarkIcon?: never }
    | { count?: never; showCheckmarkIcon: boolean }
  );

const Badge: FC<BadgeProps & Props> = ({ count, showCheckmarkIcon, ...props }) => (
  <AntdBadge
    {...props}
    count={
      showCheckmarkIcon ? (
        <BadgeContainer>
          <SvgIcon
            glyph={CheckmarkIcon}
            color={colors.black}
            size="small"
            height={12}
            width={12}
          />
        </BadgeContainer>
      ) : (
        count
      )
    }
  />
);

export default Badge;
