import { LoadingOutlined as LoadingIcon } from "@ant-design/icons";
import { css } from "@linaria/core";
import type { ComponentProps, FC, PropsWithChildren, ReactHTML, SVGProps } from "react";
import { createElement } from "react";

import colors from "theme/colors";

import Icon from "./Icon";
import SvgIcon from "./Icon/SvgIcon";

const dropdownItemCss = css`
  min-height: 24px;
  min-width: 144px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

type HtmlElement = keyof ReactHTML;

type IconType = undefined | string | FC<SVGProps<SVGSVGElement>>;

type ItemIconProps = {
  icon?: IconType;
  isLoading?: boolean;
};

type Props<T extends HtmlElement> = {
  element?: T;
  icon?: IconType;
  isLoading?: boolean;
} & ComponentProps<ReactHTML[T]>;

const DEFAULT_ELEMENT = "div";

const ItemIcon: FC<ItemIconProps> = ({ icon, isLoading }) => {
  if (isLoading) {
    return <LoadingIcon style={{ color: colors.midGrey, width: 18 }} />;
  }

  if (!icon) {
    return null;
  }

  if (typeof icon === "string") {
    return <Icon src={icon} />;
  }

  return <SvgIcon glyph={icon} />;
};

const DropdownItem = <T extends HtmlElement>({
  children,
  element = DEFAULT_ELEMENT as T,
  icon,
  isLoading,
  ...rest
}: PropsWithChildren<Props<T>>): JSX.Element =>
  createElement(
    element,
    { className: dropdownItemCss, ...rest },
    <>
      <ItemIcon icon={icon} isLoading={isLoading} />
      {children}
    </>
  );

export default DropdownItem;
