// https://github.com/iamkun/dayjs/issues/1374#issuecomment-826331788
import * as dayjs from "dayjs";
import * as weekday from "dayjs/plugin/weekday";
import * as weekOfYear from "dayjs/plugin/weekOfYear";

dayjs.extend(weekday);
dayjs.extend(weekOfYear);
const FORMAT_PATTERN = "YYYY-MM-DD";

type Options = {
  includeWeekBounds: undefined | boolean;
};

type Output = {
  startDate: string;
  endDate: string;
};

const calculateDateRange = (
  yearMonth: string,
  monthsNumber: number,
  { includeWeekBounds }: Options
): Output => {
  let startDate = dayjs(yearMonth).startOf("month");
  let endDate = startDate.endOf("month").add(monthsNumber - 1, "month");

  if (includeWeekBounds) {
    startDate = startDate.startOf("week");
    endDate = endDate.endOf("week");
  }

  return {
    startDate: startDate.format(FORMAT_PATTERN),
    endDate: endDate.format(FORMAT_PATTERN)
  };
};

export default calculateDateRange;
