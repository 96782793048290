import usePersistedState from "hooks/usePersistedState";
import { SortKeys, DEFAULT_SORT_KEY } from "components/core/TimelineSortPresentation";

const useSort = (): [sortKey: SortKeys, setSortKey: (sortKey: SortKeys) => void] => {
  const [storedSortKey, setStoredSortKey] = usePersistedState<SortKeys>({
    key: "CalendarTimelineResourceOrder",
    defaultValue: DEFAULT_SORT_KEY
  });

  const validSortKey = Object.values(SortKeys).includes(storedSortKey)
    ? storedSortKey
    : DEFAULT_SORT_KEY;

  return [validSortKey, setStoredSortKey];
};

export { useSort };
