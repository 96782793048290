import type { MenuProps } from "antd";

type ItemsType = NonNullable<MenuProps["items"]>;

const addDropdownDividers = (items: ItemsType | undefined) => {
  if (!items) {
    return;
  }

  const itemsLength = items.length;

  return items.reduce<ItemsType>((acc, item, index) => {
    acc.push(item);

    if (index !== itemsLength - 1) {
      acc.push({ type: "divider" });
    }

    return acc;
  }, []);
};

export default addDropdownDividers;
