import { useCallback, useMemo, useState } from "react";

export default function usePersistedState<T extends boolean | string | null | undefined>({
  key,
  defaultValue
}: {
  key: string;
  defaultValue: T;
}): [T, (newValue: T) => void] {
  const [value, setValue] = useState(localStorage.getItem(key) || defaultValue);

  const setAndPersistValue = useCallback(
    (newValue) => {
      localStorage.setItem(key, newValue);
      setValue(newValue);
    },
    [key, setValue]
  );

  const coercedValue = useMemo(() => {
    if (value === "undefined") {
      return undefined as T;
    }
    if (["true", "false"].includes(value as string)) {
      return (value === "true") as T;
    }
    return value as T;
  }, [value]);

  return [coercedValue, setAndPersistValue];
}
