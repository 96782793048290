import { Tooltip } from "antd";

import sortIcon from "images/sort.svg";

import Badge from "../Badge";
import Button from "../Button";
import Dropdown from "../Dropdown";
import DropdownItem from "../DropdownItem";
import Icon from "../Icon";

import type { SortOption } from "./types";
import { SortKeys } from "./types";
import { DEFAULT_SORT_KEY } from "./constants";

const DEFAULT_SORT_OPTIONS: SortOption[] = [
  {
    key: SortKeys.Index,
    name: "By Crop"
  },
  {
    key: SortKeys.LayerGroup,
    name: "By Group"
  },
  {
    key: SortKeys.PlantingLocation,
    name: "By Planting Location"
  },
  {
    key: SortKeys.SeedingDate,
    name: "Seeding Date"
  },
  {
    key: SortKeys.InGroundDate,
    name: "In Ground Date"
  },
  {
    key: SortKeys.HarvestingDate,
    name: "Harvesting Date"
  }
];

const items = DEFAULT_SORT_OPTIONS.map(({ key, name }) => ({
  key,
  label: <DropdownItem element="span">{name}</DropdownItem>
}));

type Props = {
  onChange: (key: SortKeys) => void;
  value: SortKeys;
};

const TimelineSortPresentation = ({ onChange, value }: Props) => {
  const handleMenuCLick = (params: { key: string }) => onChange(params.key as SortKeys);

  return (
    <Dropdown menu={{ selectedKeys: [value], items, onClick: handleMenuCLick }}>
      <Tooltip title="Sort order">
        <Badge showCheckmarkIcon={value !== DEFAULT_SORT_KEY}>
          <Button data-testid="sort-order-button" shape="circle">
            <Icon alt="Sort" src={sortIcon} />
          </Button>
        </Badge>
      </Tooltip>
    </Dropdown>
  );
};

export { TimelineSortPresentation };
