import { CloseCircleOutlined } from "@ant-design/icons";
import { styled } from "@linaria/react";
import type { ModalProps } from "antd";
import { Modal as AntdModal } from "antd";
import { merge } from "lodash";
import type { FC } from "react";

type Spacing = "default" | "compact";

const STYLE_SPACING_MAP: Record<Spacing, ModalProps["styles"]> = {
  compact: {
    body: { padding: "16px 24px" },
    footer: { padding: "0 18px 24px", margin: 0 },
    header: { padding: "12px 24px" }
  },
  default: {
    body: { padding: "32px 40px" },
    footer: { padding: "0 32px 40px", margin: 0 },
    header: { padding: "20px 40px" }
  }
};

const StyledModal = styled(AntdModal)<ModalProps & { spacing?: Spacing }>`
  .ant5-modal-close {
    top: ${({ spacing: spacing }) => (spacing === "compact" ? "8px" : "12px")};
  }
`;

type ModalType = FC<ModalProps & { spacing?: Spacing }> & {
  warning: typeof AntdModal.warning;
};

const Modal: ModalType = ({ styles, spacing: spacing = "default", ...props }) => (
  <StyledModal
    closeIcon={<CloseCircleOutlined />}
    styles={merge(STYLE_SPACING_MAP[spacing], styles)}
    spacing={spacing}
    {...props}
  />
);

Modal.warning = AntdModal.warning;

export default Modal;
