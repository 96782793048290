import * as Sentry from "@sentry/react";

export const initSentry = () => {
  Sentry.init({
    dsn: window.SENTRY_FRONTEND_DSN,
    integrations: [],

    // Doesn't look relevant to our code
    // https://sentry.io/answers/react-resizeobserver-loop-completed-with-undelivered-notifications/
    ignoreErrors: [
      "ResizeObserver loop limit exceeded",
      "ResizeObserver loop completed with undelivered notifications."
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.0
  });
};
