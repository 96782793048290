import type { FC, SVGAttributes } from "react";

type Props = {
  fill?: string;
} & SVGAttributes<SVGSVGElement>;

const CheckmarkIcon: FC<Props> = ({ fill = "currentColor", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 12 12"
    {...props}
  >
    <path
      d="M4.5,9.1c-.2,0-.3,0-.4-.2l-2.5-2.5c-.2-.2-.2-.6,0-.8s.6-.2.8,0l2.1,2.1,5.1-5.1c.2-.2.6-.2.8,0s.2.6,0,.8l-5.5,5.5c-.1.1-.3.2-.4.2Z"
      fill={fill}
    />
  </svg>
);

export default CheckmarkIcon;
