import { styled } from "@linaria/react";

import type { SizeType } from "./constants";
import { DEFAULT_SIZE, SIZES_MAP } from "./constants";

type Props = { size?: SizeType };

const Icon = styled.img<Props>`
  aspect-ratio: 1;
  width: ${({ size }) => SIZES_MAP[size || DEFAULT_SIZE]};
`;

export default Icon;
