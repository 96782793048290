import { Controller } from "stimulus";

export default class extends Controller {
  static values = { visibleToggles: Object };

  connect() {
    // FIXME: duped from "refresh.erb.js"
    window.VisibleToggles = this.visibleTogglesValue;
    for (const key of ["visibleActions", "visiblePlantings"]) {
      window.VisibleToggles[key] = new Set(window.VisibleToggles[key]);
    }
    window.State.markDirty();
    window.State.reloadAllCalendarViews();
  }
}
