import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  connect() {
    return undefined;
  }

  toggle() {
    const sidebar = document.querySelector(".mobile-sidebar");
    const menu = document.querySelector(".mobile-header__menu");

    if (sidebar.classList.contains("mobile-sidebar--active")) {
      sidebar.classList.remove("mobile-sidebar--active");
      menu.classList.remove("mobile-header__menu--close");
      menu.classList.add("mobile-header__menu--open");
    } else {
      sidebar.classList.add("mobile-sidebar--active");
      menu.classList.remove("mobile-header__menu--open");
      menu.classList.add("mobile-header__menu--close");
    }
  }
}
