import tippy, { hideAll } from "tippy.js";
import "tippy.js/themes/light.css";
import "tippy.js/dist/tippy.css";

function renderTag(name, color) {
  const colorStyles = `background-color: ${color + "24"}; border: 1px solid ${color};`;

  return `<div class="calendar-event-tooltip__tag" style="${colorStyles}">${name}</div>`;
}

export function removeAllTooltips() {
  hideAll({ duration: 0 });
}

/* eslint-disable camelcase */
function renderContent(title, plantingLocations = []) {
  const tagElements = plantingLocations
    .map(({ name, fill_color }) => renderTag(name, fill_color))
    .join("");

  const titleElement = `<div class="calendar-event-tooltip__title">${title}</div>`;
  const wrappedTagsElement = plantingLocations.length
    ? `<div class="calendar-event-tooltip__tags">${tagElements}</div>`
    : "";

  return `<div class="calendar-event-tooltip">${titleElement}${wrappedTagsElement}</div>`;
}
/* eslint-enable camelcase */

export function addTooltip(el, event) {
  if (!event.title) {
    return;
  }

  tippy(el, {
    content: renderContent(event.title, event.extendedProps.plantingLocations),
    delay: [350, null],
    allowHTML: true,
    arrow: false,
    theme: "light",
    offset: [0, 0],
    placement: "right"
  });
}
