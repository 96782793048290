import { Controller } from "stimulus";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction";
import { map, uniq } from "lodash";

import { addTooltip } from "./calendar_event_tooltip";
import { onCalEventClick, onCalEventDrop, onEventResize } from "./calendar_actions";
import { getFrostClass, updateCalendarSizeOnPrint, eventTitle } from "./calendar_helpers";

export default class MonthController extends Controller {
  static targets = ["fullCalendar"];

  static values = {
    eventsUrl: String,
    averageFrost: Object,
    hardFrost: Object
  };

  connect() {
    updateCalendarSizeOnPrint();

    this.element[this.identifier] = this;
    this.calendar = this._initCalendar();
    this.calendar.render();
  }

  reload() {
    this.calendar.refetchEvents();
    this.calendar.refetchResources();
  }

  _initCalendar() {
    return new Calendar(this.fullCalendarTarget, {
      plugins: [resourceTimelinePlugin, dayGridPlugin, interactionPlugin],
      height: "100%",
      rerenderDelay: 2,
      droppable: true,
      editable: true,
      eventResizableFromStart: true,
      schedulerLicenseKey: "0514747198-fcs-1606763132",
      initialView: "dayGridMonth",
      snapDuration: "1 week",
      headerToolbar: false,
      eventConstraint: {
        startTime: "2020-12-01T00:00:00",
        endTime: "2020-12-31T23:59:00"
      },
      views: {
        dayGridMonth: { fixedWeekCount: false }
      },
      eventDrop: onCalEventDrop,
      eventResize: onEventResize,
      eventClick: onCalEventClick,
      eventAllow() {
        return true;
      },
      eventDidMount({ el, event }) {
        addTooltip(el, event);
      },
      events: this._fetchData.bind(this),
      eventSourceSuccess: (data) => {
        return data.map((event) => ({
          ...event,
          display: window.State.shouldShowCalendarEvent(event) ? "auto" : "none",
          title: eventTitle(event)
        }));
      },
      dayCellClassNames: (args) => {
        return `${getFrostClass(
          args.date,
          this.averageFrostValue,
          this.hardFrostValue
        )} month`;
      }
    });
  }

  async _fetchData(fetchInfo, successCallback) {
    const url = new URL(this.eventsUrlValue, window.location.origin);

    if (fetchInfo.startStr) url.searchParams.append("start_date", fetchInfo.startStr);
    if (fetchInfo.endStr) url.searchParams.append("end_date", fetchInfo.endStr);

    const r = await fetch(url);
    const json = await r.json();
    const plantingIds = uniq(map(json, "resourceId"));

    window.State.fetchPlantingGroupings(plantingIds);
    successCallback(json);
  }
}
