import { cloneDeep, isEmpty, sortBy, flattenDeep, groupBy } from "lodash";
import type { ResourceInput } from "@fullcalendar/resource-common";
import type { EventInput } from "@fullcalendar/core";

import type { Option } from "components/core/MultiselectFilterPresentation";
import { SortKeys } from "components/core/TimelineSortPresentation";
import type { WindowWithState, ResourceGroupingMapping } from "types";
import calculateDateRange from "helpers/calculateDateRange";
import buildUrlWithParams from "helpers/buildUrlWithParams";

import { eventTitle } from "./calendar_helpers";

type ApiGroupingResource = {
  id: number;
  title: string;
};

/* eslint-disable camelcase */
type ApiResource = {
  crop_schedule_ids: number[];
  ground_occupation_start: string;
  harvesting_date: string;
  id: number;
  layer_group_ids: number[];
  planting_location_ids: number[];
  seeding_date: string;
  title: string;
};
/* eslint-enable camelcase */

type ResourcesApiResponse = {
  cropSchedulesById: Record<string, ApiGroupingResource>;
  layerGroupsById: Record<string, ApiGroupingResource>;
  plantingLocationsById: Record<string, ApiGroupingResource>;
  plantingsById: Record<string, ApiResource>;
};

type ApiEvent = {
  resourceId: number;
};

type EventsApiResponse = ApiEvent[];

type Resource = Omit<
  ApiResource,
  "id" | "crop_schedule_ids" | "layer_group_ids" | "planting_location_ids"
> & {
  id: string;
  realPlantingId: number;
  parentId?: string;
};

type Event = Omit<ApiEvent, "resourceId"> & {
  resourceId: string;
  parentResourceId: string;
  display: "auto" | "none";
  title: string;
};

type GroupingResource = Omit<ApiGroupingResource, "id"> & {
  id: string;
};

type Dispatcher = (resources: ResourceInput[], events: EventInput[]) => void;

type GroupMapping = {
  byCropSchedules: Record<string, string[]>;
  byGroups: Record<string, string[]>;
  byPlantingLocations: Record<string, string[]>;
  withoutCropSchedules: string[];
  withoutGroups: string[];
  withoutPlantingLocations: string[];
};

const EMPTY_GROUP_MAPPING: GroupMapping = {
  byCropSchedules: {},
  byGroups: {},
  byPlantingLocations: {},
  withoutCropSchedules: [],
  withoutGroups: [],
  withoutPlantingLocations: []
};

const groupingResourceToOption = (
  record: Record<string, ApiGroupingResource>,
  noGroupId: string,
  noGroupTitle: string
): Option[] => {
  const options = Object.values(record).map(({ id, title }) => ({
    title,
    id: String(id)
  }));

  return [...options, { id: noGroupId, title: noGroupTitle }];
};

const mapNumbersToString = (numbers: number[]): string[] => {
  return numbers.map((number) => String(number));
};

const NO_CROP_SCHEDULE_ID = "withoutCropSchedules";
export const NO_PLANTING_LOCATION_ID = "withoutPlantingLocations";
export const NO_LAYER_GROUP_ID = "withoutGroups";
const BY_CROP_SCHEDULES_PREFIX = "byCropSchedules";
export const BY_GROUPS_ID_PREFIX = "byGroups";
export const BY_PLANTING_LOCATIONS_ID_PREFIX = "byPlantingLocations";
const NO_PLANTING_LOCATION_TITLE = "Without location";
const NO_LAYER_GROUP_TITLE = "Without group";
const CALENDAR_EVENTS_URL = "/calendar_events.json";
const CALENDAR_RESOURCES_URL = "/calendar_resources.json";

const resourcesToGroupingMapping = (
  plantingsById: Record<string, ApiResource>
): ResourceGroupingMapping => {
  const plantings = Object.values(plantingsById);

  /* eslint-disable camelcase */
  return plantings.reduce((acc, { id, layer_group_ids, planting_location_ids }) => {
    acc[id] = {
      layerGroupIds: isEmpty(layer_group_ids)
        ? [NO_LAYER_GROUP_ID]
        : mapNumbersToString(layer_group_ids),
      plantingLocationIds: isEmpty(planting_location_ids)
        ? [NO_PLANTING_LOCATION_ID]
        : mapNumbersToString(planting_location_ids)
    };

    return acc;
  }, {} as ResourceGroupingMapping);
  /* eslint-enable camelcase */
};

type GroupFieldName =
  | typeof BY_CROP_SCHEDULES_PREFIX
  | typeof BY_GROUPS_ID_PREFIX
  | typeof BY_PLANTING_LOCATIONS_ID_PREFIX;

type NoGroupFieldName =
  | typeof NO_CROP_SCHEDULE_ID
  | typeof NO_LAYER_GROUP_ID
  | typeof NO_PLANTING_LOCATION_ID;

type Props = {
  numMonths: number;
  startingYearMonth: string;
  viewMode: "complete_weeks" | "exact";
};

type QueryParams = {
  endDate: string;
  startDate: string;
} & Pick<Props, "numMonths" | "startingYearMonth">;

export class TimelineResourceStore {
  public resourceOrderKey: SortKeys = SortKeys.Index;

  private _params: QueryParams;

  private _resourcesApiResponse: ResourcesApiResponse = {
    cropSchedulesById: {},
    layerGroupsById: {},
    plantingLocationsById: {},
    plantingsById: {}
  };

  private _eventsApiResponse: EventsApiResponse = [];

  private _groupMapping = cloneDeep<GroupMapping>(EMPTY_GROUP_MAPPING);

  private _apiFetcher: Promise<void>;

  constructor({ numMonths, startingYearMonth, viewMode }: Props) {
    this._params = this._buildParams({ numMonths, startingYearMonth, viewMode });
    this._apiFetcher = this._initApiFetcher();
  }

  dispatch(dispatcher: Dispatcher) {
    this._apiFetcher.then(() => {
      const state = (window as WindowWithState).State;

      state.plantingLocationFilterOptions = groupingResourceToOption(
        this._resourcesApiResponse.plantingLocationsById,
        NO_PLANTING_LOCATION_ID,
        NO_PLANTING_LOCATION_TITLE
      );

      state.layerGroupFilterOptions = groupingResourceToOption(
        this._resourcesApiResponse.layerGroupsById,
        NO_LAYER_GROUP_ID,
        NO_LAYER_GROUP_TITLE
      );

      state.setGroupingMapping(
        resourcesToGroupingMapping(this._resourcesApiResponse.plantingsById)
      );

      state.notifyFilterOptionsUpdateSubscribers.bind(state)();

      return dispatcher(this._prepareResources(), this._prepareEvents());
    });
  }

  reinitApiFetcher() {
    this._apiFetcher = this._initApiFetcher();
  }

  private _buildParams({
    numMonths,
    startingYearMonth,
    viewMode
  }: Pick<QueryParams, "numMonths" | "startingYearMonth"> & Pick<Props, "viewMode">) {
    const { startDate, endDate } = calculateDateRange(startingYearMonth, numMonths, {
      includeWeekBounds: viewMode === "complete_weeks"
    });

    return {
      endDate,
      numMonths,
      startDate,
      startingYearMonth
    };
  }

  private _eventsFetchUrl() {
    return buildUrlWithParams(CALENDAR_EVENTS_URL, this._params);
  }

  private _resourcesFetchUrl() {
    return buildUrlWithParams(CALENDAR_RESOURCES_URL, this._params);
  }

  private async _initApiFetcher() {
    const [resourcesApiResponse, eventsApiResponse] = await Promise.all([
      fetch(this._resourcesFetchUrl()).then(
        (r) => r.json() as Promise<ResourcesApiResponse>
      ),
      fetch(this._eventsFetchUrl()).then((r) => r.json() as Promise<EventsApiResponse>)
    ]);
    this._resourcesApiResponse = resourcesApiResponse;
    this._eventsApiResponse = eventsApiResponse;
    this._generateGroupMapping();
  }

  private _generateGroupMapping() {
    let groupMapping = cloneDeep<GroupMapping>(EMPTY_GROUP_MAPPING);

    Object.values(this._resourcesApiResponse.plantingsById).forEach((resource) => {
      groupMapping = this._populateGroupMappingByFields(
        groupMapping,
        resource,
        "crop_schedule_ids",
        BY_CROP_SCHEDULES_PREFIX,
        NO_CROP_SCHEDULE_ID
      );

      groupMapping = this._populateGroupMappingByFields(
        groupMapping,
        resource,
        "layer_group_ids",
        BY_GROUPS_ID_PREFIX,
        NO_LAYER_GROUP_ID
      );

      groupMapping = this._populateGroupMappingByFields(
        groupMapping,
        resource,
        "planting_location_ids",
        BY_PLANTING_LOCATIONS_ID_PREFIX,
        NO_PLANTING_LOCATION_ID
      );
    });

    this._groupMapping = groupMapping;
  }

  private _populateGroupMappingByFields(
    groupMapping: GroupMapping,
    resource: ApiResource,
    resourceGroupingFieldName:
      | "crop_schedule_ids"
      | "layer_group_ids"
      | "planting_location_ids",
    groupFieldName: GroupFieldName,
    noGroupFieldName: NoGroupFieldName
  ) {
    const ids = resource[resourceGroupingFieldName];
    const resourceId = String(resource.id);

    if (isEmpty(ids)) {
      groupMapping[noGroupFieldName].push(resourceId);

      return groupMapping;
    }

    ids.forEach((id) => {
      if (!groupMapping[groupFieldName][id])
        groupMapping[groupFieldName][id] = [] as string[];

      groupMapping[groupFieldName][id].push(resourceId);
    });

    return groupMapping;
  }

  private _prepareEvents() {
    switch (this.resourceOrderKey) {
      case SortKeys.LayerGroup:
        return this._prepareGroupedEvents(BY_GROUPS_ID_PREFIX, NO_LAYER_GROUP_ID);

      case SortKeys.PlantingLocation:
        return this._prepareGroupedEvents(
          BY_PLANTING_LOCATIONS_ID_PREFIX,
          NO_PLANTING_LOCATION_ID
        );

      case SortKeys.Index:
        return this._prepareGroupedEvents(BY_CROP_SCHEDULES_PREFIX);

      default:
        return this._preparePlainEvents();
    }
  }

  private _prepareGroupedEvents(
    groupFieldName: GroupFieldName,
    noGroupFieldName?: NoGroupFieldName
  ) {
    const eventsByResourceId = groupBy(this._eventsApiResponse, "resourceId");

    let events = Object.entries(this._groupMapping[groupFieldName]).reduce(
      (acc, [groupingId, resourceIds]) => {
        if (isEmpty(resourceIds)) return acc;

        const parentId = `${groupFieldName}-${groupingId}`;

        const groupEvents = resourceIds.map((resourceId) => {
          return (eventsByResourceId[resourceId] || []).map((apiEvent) =>
            this._normalizeEvent(`${parentId}-${resourceId}`, apiEvent)
          );
        });

        return [...acc, ...groupEvents];
      },
      [] as Array<Array<Event>>
    );

    if (noGroupFieldName) {
      const resourceIds = this._groupMapping[noGroupFieldName];
      const parentId = noGroupFieldName;

      const groupEvents = resourceIds.map((resourceId) =>
        (eventsByResourceId[resourceId] || []).map((apiEvent) =>
          this._normalizeEvent(`${parentId}-${resourceId}`, apiEvent)
        )
      );

      events = [...events, ...groupEvents];
    }

    return flattenDeep<Event>(events);
  }

  private _preparePlainEvents(): Event[] {
    return this._eventsApiResponse.map((apiEvent) =>
      this._normalizeEvent(String(apiEvent.resourceId), apiEvent)
    );
  }

  private _prepareResources() {
    switch (this.resourceOrderKey) {
      case SortKeys.LayerGroup:
        return this._prepareGroupedResources(
          BY_GROUPS_ID_PREFIX,
          "layerGroupsById",
          NO_LAYER_GROUP_ID,
          NO_LAYER_GROUP_TITLE
        );

      case SortKeys.PlantingLocation:
        return this._prepareGroupedResources(
          BY_PLANTING_LOCATIONS_ID_PREFIX,
          "plantingLocationsById",
          NO_PLANTING_LOCATION_ID,
          NO_PLANTING_LOCATION_TITLE
        );

      case SortKeys.Index:
        return this._prepareGroupedResources(
          BY_CROP_SCHEDULES_PREFIX,
          "cropSchedulesById"
        );

      default:
        return this._prepareSortedResources();
    }
  }

  private _prepareSortedResources() {
    const plantings = Object.values(this._resourcesApiResponse.plantingsById);
    const sortedPlantings = sortBy(plantings, this.resourceOrderKey);

    return sortedPlantings.map((planting) =>
      this._normalizeResource(String(planting.id), planting)
    );
  }

  private _prepareGroupedResources(
    groupFieldName: GroupFieldName,
    apiGroupingFieldName:
      | "cropSchedulesById"
      | "layerGroupsById"
      | "plantingLocationsById",
    noGroupFieldName?: NoGroupFieldName,
    emptyGroupName?: string
  ) {
    let resources = Object.entries(this._groupMapping[groupFieldName]).reduce(
      (acc, [groupingId, resourceIds]) => {
        if (isEmpty(resourceIds)) return acc;

        const parentId = `${groupFieldName}-${groupingId}`;

        const groupResources = resourceIds.map((resourceId) =>
          this._normalizeResource(
            `${parentId}-${resourceId}`,
            this._resourcesApiResponse.plantingsById[resourceId],
            parentId
          )
        );

        const apiGrouping = this._resourcesApiResponse[apiGroupingFieldName][groupingId];
        const grouping = this._normalizeGrouping(parentId, apiGrouping);

        acc.push([grouping, sortBy(groupResources, "title")]);

        return acc;
      },
      [] as Array<Array<GroupingResource | Resource[]>>
    );

    resources = sortBy(
      resources,
      (groupedResource) => (groupedResource[0] as GroupingResource).title
    );

    if (noGroupFieldName && emptyGroupName) {
      const resourceIds = this._groupMapping[noGroupFieldName];
      const parentId = noGroupFieldName;

      const groupResources = resourceIds.map((resourceId) =>
        this._normalizeResource(
          `${parentId}-${resourceId}`,
          this._resourcesApiResponse.plantingsById[resourceId],
          parentId
        )
      );

      const grouping = { id: parentId, title: emptyGroupName };

      resources.push([grouping, groupResources]);
    }

    return flattenDeep<Resource | GroupingResource>(resources);
  }

  private _normalizeResource(
    newId: string,
    resource: ApiResource,
    parentId?: string
  ): Resource {
    const {
      id: realPlantingId,
      crop_schedule_ids: _skippedCropScheduleIds,
      layer_group_ids: _skippedLayerGroupIds,
      planting_location_ids: _skippedPlantingLocationIds,
      ...rest
    } = resource;

    const semiResult = { ...rest, realPlantingId, id: newId };

    if (parentId) return { ...semiResult, parentId };

    return semiResult;
  }

  private _normalizeEvent(newResourceId: string, event: ApiEvent): Event {
    const { resourceId: _oldResourceId, ...rest } = event;
    const state = (window as WindowWithState).State;

    return {
      ...rest,
      resourceId: newResourceId,
      parentResourceId: newResourceId,
      display: state.shouldShowCalendarEvent(event) ? "auto" : "none",
      title: eventTitle(event)
    };
  }

  private _normalizeGrouping(
    newId: string,
    grouping: ApiGroupingResource
  ): GroupingResource {
    return { ...grouping, id: newId };
  }
}
