import type { FC, SVGProps } from "react";

import colors from "theme/colors";

import type { SizeType } from "./constants";
import { DEFAULT_SIZE, SIZES_MAP } from "./constants";

type Props = {
  description?: string;
  glyph: FC<SVGProps<SVGSVGElement>>;
  size?: SizeType;
} & SVGProps<SVGSVGElement>;

const SvgIcon: FC<Props> = ({
  glyph,
  color = colors.midGrey,
  size = DEFAULT_SIZE,
  ...rest
}) => glyph({ width: SIZES_MAP[size], height: SIZES_MAP[size], color, ...rest });

export default SvgIcon;
