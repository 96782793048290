import { Controller } from "stimulus";

import { GROWING_TYPES } from "consts/crops";

export default class extends Controller {
  static targets = [
    "name",
    "varietyName",
    "scheduleSeason",
    "frostSelect",
    "lastFrost",
    "firstFrost",
    "frostOffset",
    "frostDate",
    "growingType",
    "daysToMaturity",
    "daysToMaturityText",
    "harvestWindowLengthHtml",
    "timeBetweenSeedingAndTransplanting",
    "harvestWindow",
    "harvestExtendToEndOfSeason",
    "suggestedSuccesssionSpacing",
    "frostKill",
    "cropSeason",
    "color",
    "daysToMaturitySeedingText",
    "daysToMaturitySelect",
    "seedingDateType",
    "relativeSeedingDateForm",
    "manualSeedingDateForm"
  ];

  connect() {
    this.element[this.identifier] = this;
    super.connect();
    if (this.scheduleSeasonTarget.value == "fall") {
      this.frostOffsetTarget.value = this.firstFrostTarget.value;
    } else if (this.scheduleSeasonTarget.value == "spring") {
      this.frostOffsetTarget.value = this.lastFrostTarget.value;
    }
    this.scheduleSeason();
    this.growingType();
    this.harvestExtendToEndOfSeason();

    this.showCorrectSeedingDateFormType();
  }

  showCorrectSeedingDateFormType() {
    const val = this.seedingDateTypeTarget.value;
    switch (val) {
      case "relative":
        this.relativeSeedingDateFormTarget.classList.remove("hidden");
        this.manualSeedingDateFormTarget.classList.add("hidden");
        break;
      case "manual":
        this.relativeSeedingDateFormTarget.classList.add("hidden");
        this.manualSeedingDateFormTarget.classList.remove("hidden");
        break;
      default:
        throw new Error(`expected relative or manual, but got ${val}`);
    }
  }

  submit() {
    return undefined;
  }

  scheduleSeason() {
    let valueToSet = 0;

    if (this.scheduleSeasonTarget.value == "fall") {
      this.frostDateTarget.innerHTML = "First";
      valueToSet = this.firstFrostTarget.value;
    } else if (this.scheduleSeasonTarget.value == "spring") {
      this.frostDateTarget.innerHTML = "Last";
      valueToSet = this.lastFrostTarget.value;
    }

    if (valueToSet >= 0) {
      this.frostSelectTarget.value = "after";
    } else {
      this.frostSelectTarget.value = "before";
    }
    this.frostOffsetTarget.value = Math.abs(valueToSet);
  }

  setFrostValue() {
    this.frostSelect();
  }

  updateFrostValue() {
    if (this.frostOffsetTarget.value >= 0) {
      // do nothing
    } else {
      if (this.frostSelectTarget.value == "before") {
        this.frostSelectTarget.value = "after";
      } else {
        this.frostSelectTarget.value = "before";
      }
    }
    this.frostOffsetTarget.value = Math.abs(this.frostOffsetTarget.value);
  }

  frostSelect() {
    this.updateFrostValue();
    this.frostOffsetTarget.value = Math.abs(this.frostOffsetTarget.value);
    const sign = this.frostSelectTarget.value == "after" ? 1 : -1;
    if (this.scheduleSeasonTarget.value == "fall") {
      this.firstFrostTarget.value = sign * this.frostOffsetTarget.value;
    } else if (this.scheduleSeasonTarget.value == "spring") {
      this.lastFrostTarget.value = sign * this.frostOffsetTarget.value;
    }
  }

  cropSeason() {
    return undefined;
  }

  growingType() {
    if (this.growingTypeTarget.value == GROWING_TYPES.transplanting) {
      this.timeBetweenSeedingAndTransplantingTarget.classList.remove("hidden");
      this.daysToMaturitySelectTarget.classList.remove("hidden");
      this.daysToMaturitySeedingTextTarget.classList.add("hidden");
    } else if (this.growingTypeTarget.value == GROWING_TYPES.seeding) {
      this.timeBetweenSeedingAndTransplantingTarget.classList.add("hidden");
      this.daysToMaturitySelectTarget.value = GROWING_TYPES.seeding;
      this.daysToMaturitySelectTarget.classList.add("hidden");
      this.daysToMaturitySeedingTextTarget.classList.remove("hidden");
    }
  }

  harvestExtendToEndOfSeason() {
    if (this.harvestExtendToEndOfSeasonTarget.checked) {
      this.harvestWindowLengthHtmlTarget.classList.add("hidden");
    } else {
      this.harvestWindowLengthHtmlTarget.classList.remove("hidden");
    }
  }
}
