import { Controller } from "stimulus";
import Rails from "@rails/ujs";

import { triggerYearMonthChange } from "controllers/calendar_helpers";

export default class extends Controller {
  connect() {
    return undefined;
  }

  submitSelect() {
    const formData = new FormData(this.element);

    triggerYearMonthChange({
      yearMonth: formData.get("starting_year_month"),
      monthsNumber: parseInt(formData.get("num_months"))
    });

    Rails.fire(this.element, "submit");
  }
}
