/* eslint-disable */
import State from "state.js";
window.State = State;

import Rails from "@rails/ujs";
Rails.start();

import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();

import { initSentry } from "sentry";
initSentry();

import "@hotwired/turbo-rails";

import "controllers";
import "../stylesheets/application.scss";
import "helpers/initTheme";

require.context("../images", true);
