export enum SortKeys {
  Index = "index",
  LayerGroup = "layer_group",
  PlantingLocation = "planting_location",
  SeedingDate = "seeding_date",
  InGroundDate = "ground_occupation_start",
  HarvestingDate = "harvesting_date"
}

export type SortOption = {
  key: SortKeys;
  name: string;
};
