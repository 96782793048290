/* eslint-disable @typescript-eslint/no-var-requires */
const TIMELINE_DAY_CONTAINER_WIDTH = 6;

const colors = require("./colors");

// theme variables not defined in antd
module.exports = {
  "border-base": `1px solid ${colors.lightGrey}`,
  "border-radius": "20px",
  "btn-primary-bg-color-hover": colors.primaryDarker,
  "btn-primary-bg-color-active": colors.primaryDarker,
  "btn-primary-focus-border-color": colors.warning,
  "color-button-size": "25px",
  "disabled-bg": colors.lightGrey,
  "disabled-color": colors.midGrey,
  "timeline-day-container-width": TIMELINE_DAY_CONTAINER_WIDTH,
  "timeline-day-container-width-px": `${TIMELINE_DAY_CONTAINER_WIDTH}px`,
  "heading-font": "Raleway, Helvetica, sans-serif",
  "switch-handle-unchecked-bg-color": colors.midGrey,
  "switch-unchecked-bg-color": colors.lightGrey,
  "switch-unchecked-color": colors.midGrey,
  "switch-font-size": "13px",
  "text-small-font-size": "13px",
  "text-font": "Inter, Helvetica, sans-serif"
};
