import type { ButtonProps } from "antd";
import { Button as AntdButton, ConfigProvider } from "antd";
import type { ForwardedRef } from "react";
import { forwardRef } from "react";

import type { ComponentTheme } from "@shared/types/ComponentTheme";

type Props = ButtonProps & {
  componentTheme?: ComponentTheme<"Button">;
};

const Button = forwardRef(
  (
    { componentTheme, ...props }: Props,
    ref: ForwardedRef<HTMLButtonElement>
  ): JSX.Element => (
    <ConfigProvider theme={{ components: { Button: componentTheme } }}>
      <AntdButton {...props} ref={ref} />
    </ConfigProvider>
  )
);

Button.displayName = "Button";

export default Button;
