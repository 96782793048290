import { Controller } from "stimulus";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction";
import dayjs from "dayjs";
import { isEmpty, intersection } from "lodash";

import { SortKeys } from "components/core/TimelineSortPresentation";
import calculateDateRange from "helpers/calculateDateRange";

import {
  TimelineResourceStore,
  NO_PLANTING_LOCATION_ID,
  NO_LAYER_GROUP_ID,
  BY_GROUPS_ID_PREFIX,
  BY_PLANTING_LOCATIONS_ID_PREFIX
} from "./timeline_resource_store";
import { addTooltip } from "./calendar_event_tooltip";
import { onCalEventClick, onCalEventDrop, onEventResize } from "./calendar_actions";
import { buildBackgroundForCropEvents, getFrostClass } from "./calendar_helpers";

export default class TimelineController extends Controller {
  static targets = ["fullCalendar"];

  static values = {
    averageFrost: Object,
    hardFrost: Object,
    numMonths: Number,
    startingYearMonth: String,
    viewMode: String
  };

  connect() {
    this.element[this.identifier] = this;
    this.resourceStore = new TimelineResourceStore({
      numMonths: this.numMonthsValue,
      startingYearMonth: this.startingYearMonthValue,
      viewMode: this.viewModeValue
    });
    this.calendar = this._initCalendar(this.resourceStore);

    this.calendar.render();
    this._dispatchResourcesAndEvents();

    window.State.listenSidebarFiltersOrToggles((calendarToggleTracker) => {
      for (const resource of this.calendar.getResources()) {
        this._showOrHideResource(resource, calendarToggleTracker);
      }
    });
  }

  reload() {
    this.resourceStore.reinitApiFetcher();
    this._dispatchResourcesAndEvents();
  }

  setResourceOrderKey(key) {
    this.resourceStore.resourceOrderKey = key;
    this.calendar.setOption("resourceOrder", key);
    this._dispatchResourcesAndEvents();
  }

  _dispatchResourcesAndEvents() {
    this.resourceStore.dispatch((resources, events) => {
      this.calendar.setOption("resources", resources);
      this.calendar.setOption("events", events);
    });
  }

  _dateRange() {
    return calculateDateRange(this.startingYearMonthValue, this.numMonthsValue, {
      includeWeekBounds: this.viewModeValue === "complete_weeks"
    });
  }

  _initCalendar() {
    const { endDate, startDate } = this._dateRange();

    return new Calendar(this.fullCalendarTarget, {
      plugins: [resourceTimelinePlugin, dayGridPlugin, interactionPlugin],
      droppable: true,
      rerenderDelay: 2,
      editable: true,
      eventResizableFromStart: true,
      schedulerLicenseKey: "0514747198-fcs-1606763132",
      initialView: "resourceTimeline15months",
      initialDate: startDate,
      views: {
        resourceTimeline15months: {
          type: "resourceTimeline",
          duration: { months: this.numMonthsValue, weeks: 1 }
        }
      },
      headerToolbar: false,
      height: "100%",
      resourceAreaWidth: "150px",
      slotLabelInterval: { weeks: 1 },
      slotLabelFormat: [{ month: "long" }, { day: "numeric" }],
      resourceOrder: SortKeys.Index,
      eventClick: onCalEventClick,
      eventDrop: onCalEventDrop,
      eventResize: onEventResize,
      resourceAreaColumns: [
        {
          field: "title",
          headerContent: "Crop"
        }
      ],
      eventClassNames({ event }) {
        const classes = [];

        const start = dayjs(Math.max(event.start, new Date(startDate)));
        const daysToMoveEventStart = start.day();
        if (daysToMoveEventStart) {
          classes.push(`event-${daysToMoveEventStart}-days-forward`);
        }

        const end = dayjs(Math.min(event.end, new Date(endDate)));
        const daysToMoveEventEnd = end.day() ? 6 - end.day() : 0;
        if (daysToMoveEventEnd) {
          classes.push(`event-${daysToMoveEventEnd}-days-back`);
        }

        return classes.join(" ");
      },
      eventDidMount({ el, event }) {
        addTooltip(el, event);
        buildBackgroundForCropEvents(event.extendedProps.parentResourceId);
      },
      // this hides lanes that have their plantings toggled as hidden
      resourceLaneDidMount: ({ resource }) => {
        this._showOrHideResource(resource, window.State.calendarToggleTracker);
      },
      slotLaneClassNames: (args) => {
        return getFrostClass(args.date, this.averageFrostValue, this.hardFrostValue);
      }
    });
  }

  // TODO: this is a bit of a hack.... we should have an internal mapping between planting IDs and their parents
  //       which should maybe go into the TimelineResourceStore. this seems to work for now
  //
  // the potentially slow part about this is that we could be doing `isResourceTitleVisible` calls in a loop
  _showOrHideResource(resource, calendarToggleTracker) {
    this._showOrHidePlantingLane(
      resource.id,
      this._isResourceVisible(resource, calendarToggleTracker)
    );
  }

  _isResourceVisible(resource, calendarToggleTracker) {
    const realPlantingId = resource.extendedProps.realPlantingId;

    if (realPlantingId) {
      const isTitleVisible = calendarToggleTracker.isResourceTitleVisible(resource.title);

      return (
        isTitleVisible &&
        calendarToggleTracker.isPlantingVisible(realPlantingId) &&
        this._isResourceSelectedByFilter(resource, calendarToggleTracker)
      );
    } else {
      return this._anyChildEnabled(resource, calendarToggleTracker);
    }
  }

  _isResourceSelectedByFilter(resource, calendarToggleTracker) {
    const { groupingMapping, selectedLayerGroupIds, selectedPlantingLocationIds } =
      calendarToggleTracker;

    const isResourceSelectedByLayerGroupFilter = this._isResourceSelectedBySpecificFilter(
      resource,
      groupingMapping,
      selectedLayerGroupIds,
      SortKeys.LayerGroup,
      NO_LAYER_GROUP_ID,
      BY_GROUPS_ID_PREFIX,
      "layerGroupIds"
    );

    const isResourceSelectedByPlantingLocationFilter =
      this._isResourceSelectedBySpecificFilter(
        resource,
        groupingMapping,
        selectedPlantingLocationIds,
        SortKeys.PlantingLocation,
        NO_PLANTING_LOCATION_ID,
        BY_PLANTING_LOCATIONS_ID_PREFIX,
        "plantingLocationIds"
      );

    return (
      isResourceSelectedByLayerGroupFilter && isResourceSelectedByPlantingLocationFilter
    );
  }

  _isResourceSelectedBySpecificFilter(
    { extendedProps: { realPlantingId }, _resource: { parentId } },
    groupingMapping,
    selectedIds,
    matchingOrderKey,
    noGroupId,
    specificParentPrefix,
    groupingIdsKey
  ) {
    if (isEmpty(selectedIds)) return true;

    const resourceOrderKey = this.resourceStore.resourceOrderKey;

    if (resourceOrderKey === matchingOrderKey) {
      return selectedIds.some((id) => {
        const idToCompare = id === noGroupId ? id : `${specificParentPrefix}-${id}`;

        return idToCompare === parentId;
      });
    } else {
      const mappingByPlantingId = groupingMapping[realPlantingId];
      const idsByPlanting = mappingByPlantingId
        ? mappingByPlantingId[groupingIdsKey]
        : [];

      return !isEmpty(intersection(idsByPlanting, selectedIds));
    }
  }

  _anyChildEnabled(resource, calendarToggleTracker) {
    return resource
      .getChildren()
      .some((resource) => this._isResourceVisible(resource, calendarToggleTracker));
  }

  _showOrHidePlantingLane(generatedPlantingId, onOrOff) {
    const els = document.body.querySelectorAll(
      `[data-resource-id="${generatedPlantingId}"]`
    );

    for (const el of els) {
      if (onOrOff) {
        el.classList.remove("hidden");
      } else {
        el.classList.add("hidden");
      }
    }
  }
}
