import { decamelizeKeys } from "humps";
import { pickBy } from "lodash";

const buildUrlWithParams = (url: string, queryParams: Record<string, unknown>) => {
  const filteredQueryParams = pickBy(queryParams, Boolean);

  return Object.keys(filteredQueryParams).length
    ? `${url}?${new URLSearchParams(
        decamelizeKeys(filteredQueryParams) as Record<string, string>
      ).toString()}`
    : url;
};

export default buildUrlWithParams;
