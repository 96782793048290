import humanizeTaskType from "helpers/humanizeTaskType";

const inFrostZone = (day, month, frost) => {
  if (frost.start_month < frost.end_month) {
    if (month > frost.start_month && month < frost.end_month) {
      return true;
    }
  }

  if (frost.start_month > frost.end_month) {
    if (month > frost.start_month || month < frost.end_month) {
      return true;
    }
  }

  if (frost.start_month == frost.end_month) {
    if (day >= frost.start_day && day <= frost.end_day && month == frost.start_month) {
      return true;
    } else {
      return false;
    }
  }

  if (month == frost.start_month && day >= frost.start_day) {
    return true;
  }

  if (month == frost.end_month && day <= frost.end_day) {
    return true;
  }

  return false;
};

export const getFrostClass = (date, averageFrost, hardFrost) => {
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const isAverageFrost = inFrostZone(day, month, averageFrost);
  const isHardFrost = inFrostZone(day, month, hardFrost);

  if (isHardFrost) return "hard-frost";
  if (isAverageFrost) return "average-frost";
};

export const updateCalendarSizeOnPrint = () => {
  const mql = window.matchMedia("print");

  mql.onchange = (e) => {
    if (e.matches) {
      const calendars = Array.from(document.getElementsByClassName("calendar"));

      calendars.forEach((el) => {
        if (el.year?.calendar) {
          el.year.calendar.updateSize();
        }
        if (el.month?.calendar) {
          el.month.calendar.updateSize();
        }
      });
    }
  };
};

const backgroundProperties = (eventsRow) => {
  const heightDiff = 2;
  const parentPosition = eventsRow.getBoundingClientRect();

  const firstEvent = eventsRow.querySelector(
    ".fc-timeline-event-harness:first-child .fc-timeline-event"
  );
  const lastEvent = eventsRow.querySelector(
    ".fc-timeline-event-harness .fc-timeline-event.harvesting"
  );

  if (!(firstEvent && lastEvent)) {
    return null;
  }

  const firstEventPosition = firstEvent.getBoundingClientRect();
  const lastEventPosition = lastEvent.getBoundingClientRect();

  const backgroundColor = firstEvent.style.backgroundColor;
  const top = firstEventPosition.top - parentPosition.top + heightDiff / 2;
  const left = firstEventPosition.left - parentPosition.left;
  const height = firstEventPosition.height - heightDiff;
  const width = lastEventPosition.right - firstEventPosition.left;

  return { backgroundColor, top, left, height, width };
};

export const buildBackgroundForCropEvents = (resourceId) => {
  const backgroundClassName = "crop-timeline-background";
  const eventsRow = document.querySelector(
    `.fc-timeline-lane[data-resource-id="${resourceId}"] .fc-timeline-events`
  );

  if (!eventsRow) {
    return;
  }

  const properties = backgroundProperties(eventsRow);

  if (!properties) {
    return;
  }

  const background =
    eventsRow.querySelector(`.${backgroundClassName}`) || document.createElement("div");

  background.classList.add(backgroundClassName);
  background.style.backgroundColor = properties.backgroundColor;
  background.style.height = `${properties.height}px`;
  background.style.top = `${properties.top}px`;
  background.style.left = `${properties.left}px`;
  background.style.width = `${properties.width}px`;

  eventsRow.appendChild(background);
};

export const onYearMonthChange = (callback) => {
  document.addEventListener("calendarYearMonthChanged", callback, false);
};

export const removeYearMonthChangeListener = (callback) => {
  document.removeEventListener("calendarYearMonthChanged", callback);
};

export const triggerYearMonthChange = ({ yearMonth, monthsNumber }) => {
  const event = new CustomEvent("calendarYearMonthChanged", {
    detail: { yearMonth, monthsNumber }
  });
  document.dispatchEvent(event);
};

export const eventTitle = (event) =>
  `${humanizeTaskType(event.taskType)} ${event.resourceTitle}`;
