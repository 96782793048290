import { Controller } from "stimulus";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction";
import dayjs from "dayjs";

import { addTooltip } from "./calendar_event_tooltip";
import {
  onCalEventClick,
  onCalEventDrop,
  onEventResize,
  onEventReceive
} from "./calendar_actions";
import { getFrostClass, updateCalendarSizeOnPrint, eventTitle } from "./calendar_helpers";

export default class YearController extends Controller {
  static targets = ["fullCalendar"];

  static values = {
    monthName: String,
    calendarStart: String, // Calendar Start Date (INCLUDES PADDING)
    duration: Number, // Total number of days
    monthNumber: Number,
    year: Number,
    averageFrost: Object,
    hardFrost: Object
  };

  connect() {
    updateCalendarSizeOnPrint();

    this.element[this.identifier] = this;
    const months = new Map([
      [0, "January"],
      [1, "February"],
      [2, "March"],
      [3, "April"],
      [4, "May"],
      [5, "June"],
      [6, "July"],
      [7, "August"],
      [8, "September"],
      [9, "October"],
      [10, "November"],
      [11, "December"]
    ]);
    const startDay = dayjs(this.calendarStartValue);
    this.calendar = new Calendar(this.fullCalendarTarget, {
      plugins: [resourceTimelinePlugin, dayGridPlugin, interactionPlugin],
      schedulerLicenseKey: "0514747198-fcs-1606763132",
      droppable: true,
      rerenderDelay: 2,
      editable: true,
      eventResizableFromStart: true,
      initialView: "dayGrid",
      duration: { days: this.durationValue },
      dayHeaderContent: (args) => {
        if (months.get(args.date.getMonth()) == this.monthNameValue) {
          return args.date.getDate();
        } else if (args.date.getDate() == startDay.date()) {
          return this.monthNameValue;
        } else {
          return "";
        }
      },
      dayCellClassNames: (args) => {
        const frostClass = getFrostClass(
          args.date,
          this.averageFrostValue,
          this.hardFrostValue
        );

        if (months.get(args.date.getMonth()) != this.monthNameValue) {
          if (args.date.getDate() == startDay.date()) {
            return "w-24 bg-clip-padding month-name-cell";
          }
          return "bg-clip-padding day-extra-cell";
        }
        return `day-cell ${frostClass}`;
      },
      dayHeaderClassNames: (args) => {
        const frostClass = getFrostClass(
          args.date,
          this.averageFrostValue,
          this.hardFrostValue
        );

        if (months.get(args.date.getMonth()) != this.monthNameValue) {
          if (args.date.getDate() == startDay.date()) {
            return "w-24 bg-clip-padding month-name-header";
          }
          return "bg-clip-padding day-extra-header";
        }
        return `day-header ${frostClass}`;
      },
      initialDate: this.calendarStartValue,
      defaultAllDay: true,
      headerToolbar: false,
      height: "auto",
      events: (fetchInfo, successCallback) => {
        return window.State.getCalendarEvents(
          this.yearValue,
          this.monthNumberValue,
          successCallback
        );
      },
      eventSourceSuccess: (data) => {
        return data.map((event) => ({
          ...event,
          display: window.State.shouldShowCalendarEvent(event) ? "auto" : "none",
          title: eventTitle(event)
        }));
      },
      eventDragStart: ({ event }) => {
        window.State.setLastDraggedEvent(event._instance.instanceId, event.start);
      },
      eventClick: onCalEventClick,
      eventDrop: onCalEventDrop,
      eventReceive: onEventReceive,
      eventResize: onEventResize,
      eventDidMount: ({ el, event }) => {
        el.setAttribute("data-task", event.extendedProps["task"]);
        el.setAttribute("data-planting-id", event.extendedProps["planting"]);
        addTooltip(el, event);
      }
      // this is used for receiving events dropped from other calendars. the year calendar is structured as N
      // different calendars for number of months, so we expect drags into this month from other months.
    });
    this.calendar.render();
  }

  reload() {
    this.calendar.refetchEvents();
    this.calendar.refetchResources();
  }
}
