/* eslint-disable @typescript-eslint/no-var-requires */
// variables to use in modifyVars for less-loader
// See available variables in
// node_modules/antd/es/style/color
// node_modules/antd/es/style/themes

const colors = require("./colors");
const seedtime = require("./seedtime");

module.exports = {
  "alert-success-border-color": colors.lightGrey,
  "alert-padding": "8px",
  "body-background": colors.pageBackground,
  "border-color-base": colors.lightGrey,
  "border-color-split": colors.lightGrey,
  "border-radius-base": "8px",
  "border-style-base": "solid",
  "border-width-base": "1px",
  "btn-height-lg": "44px",
  "btn-font-size-lg": "16px",
  "btn-primary-bg": colors.primary,
  "control-padding-horizontal": "14px",
  "font-size-base": "16px",
  "font-family": seedtime["text-font"],
  "link-color": colors.primary,
  "red-base": "#E27367",
  "error-color": colors.error,
  "select-dropdown-vertical-padding": "10px",
  "switch-padding": "2px",
  "switch-lg-height": "30px",
  "switch-lg-min-width": "60px",
  "switch-lg-inner-margin-min": "9px",
  "switch-lg-inner-margin-max": "33px",
  "switch-lg-pin-size": "22px",
  "switch-lg-padding": "4px",
  "input-height-base": "44px",
  "input-hover-border-color": colors.primary,
  "input-padding-horizontal-base": "13px",
  "input-padding-vartical-base": "8.5px",
  "margin-xlg": "32px",
  "margin-lg": "24px",
  "margin-md": "16px",
  "margin-sm": "12px",
  "margin-xs": "8px",
  "margin-xxs": "4px",
  "modal-footer-border-width": "0",
  "padding-xxl": "40px",
  "padding-xlg": "32px",
  "padding-lg": "24px",
  "padding-md": "16px",
  "padding-sm": "12px",
  "padding-xs": "8px",
  "padding-xxs": "4px",
  "primary-color": colors.primary,
  "tabs-highlight-color": colors.black,
  "tabs-active-color": colors.primary,
  "tabs-ink-bar-color": colors.primary,
  "text-color": colors.textPrimary,
  "text-color-secondary": colors.darkGrey,
  "text-color-disabled": colors.lightGrey
};
