import type { DropdownProps } from "antd";
import { Dropdown as AntdDropdown } from "antd";
import type { FC } from "react";
import { useMemo } from "react";

import addDropdownDividers from "helpers/addDropdownDividers";

type Props = {
  includeSeparators?: boolean;
} & DropdownProps;

const Dropdown: FC<Props> = ({ includeSeparators = true, menu, ...props }) => {
  const items = useMemo(() => {
    if (!includeSeparators) {
      return menu?.items;
    }

    return addDropdownDividers(menu?.items);
  }, [includeSeparators, menu?.items]);

  return <AntdDropdown {...props} menu={{ ...menu, items }} />;
};

export default Dropdown;
