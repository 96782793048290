export const SIZES_MAP = {
  small: "12px",
  medium: "18px",
  large: "24px",
  xlarge: "30px",
  // Improve naming once we have a clearer view of all needed sizes
  huge: "64px"
};

export const DEFAULT_SIZE = "medium";

export type SizeType = keyof typeof SIZES_MAP;
