module.exports = {
  accentLight: "#fff8c3",
  accent: "#ffce50",
  accentDarker: "#fab349",
  borderSecondary: "#dbdbdb",
  black: "#332B25",
  darkGrey: "#413E3C",
  error: "#F04F4F",
  frost: "#F1FAFF",
  hardFrost: "#E1F4FF",
  lightGrey: "#DBDBDB",
  linkHover: "#97cc6e",
  midGrey: "#A1A1A1",
  pageBackground: "#F6F5F3",
  primary: "#79C048",
  primaryDarker: "#5E9834",
  primaryLight: "#F1F4EB",
  secondary: "#786658",
  success: "#52C048",
  textPrimary: "#332B25",
  warning: "#FAB349",
  white: "#FFFFFF"
};
