import type featureFlags from "../../../config/feature_flags.json";

// using feature flags as object to get keys as specific values for typechecking
export type FeatureFlagNames = keyof typeof featureFlags;

// Making it a hook and not helper function to support possible future switch to http request instead of global var.
const useIsFeatureEnabled = (featureName: FeatureFlagNames) => {
  return window.SEEDTIME_FEATURES.includes(featureName);
};

export default useIsFeatureEnabled;
